.navbar {
    font-family: 'Aileron';
    background-color: #F3F3F3;
}

.navbar-brand {
    font-weight: bold;
    font-size: 28px !important;
    color: #443F43 !important;
    margin-left: 2rem;
}

.navbar-seperator {
    float: right;
}

.navbar-icon {
    font-weight: bold;
    margin-right: 2rem;
}

.navbar-icon {
    width: 40px;
}