@font-face {
  font-family: 'Aileron';
  src: local('Aileron'), url(./fonts/Aileron-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Aileron';
  src: local('Aileron'), url(./fonts/Aileron-Heavy.ttf) format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Aileron';
  src: local('Aileron'), url(./fonts/Aileron-Bold.ttf) format('truetype');
  font-weight: bold;
}


@font-face {
  font-family: 'Aileron';
  src: local('Aileron'), url(./fonts/Aileron-Italic.ttf) format('truetype');
  font-style: italic;
}


body {
  margin: 0;
  font-family: 'Aileron';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #443F43;
  background-color: #F3F3F3;
}

code {
  font-family: 'Aileron';
}
